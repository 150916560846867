import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import AgeGateBoolean from "../components/AgeGate/AgegateBoolean"
import { FooterHome } from "../components/FooterHome/FooterHome"

const MedallaQR = ({ data, location }) => {
    const [age, setAge] = useState(true)

    useEffect(() => {
        let local = localStorage.getItem("age") || sessionStorage.getItem("age")
        if (local) return setAge(false)
        window.dataLayer.push({
            brand: "Corona",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "",
            pageType: "MedallaQr",
            SiteType: "MedallaQr",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.cervezacorona.com.ar/medalla-qr",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "" ,
        })
    }, [])

    const medalla = data.allMedallaQr?.edges[0]?.node

    return !age ? (
        <Layout>
            <SEO title="MedallaQR" />
            {medalla.active && (
                <img src={medalla.img} alt="medalla" style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    margin: 0
                }}/>
            )}
            <FooterHome />
        </Layout>
    ) : (
        <AgeGateBoolean location={location} setAge={setAge} />
    )
}

export const medalla = graphql`
   query {
    allMedallaQr {
      edges {
        node {
          img
          link
          active
          id
        }
      }
    }
  }
`

export default MedallaQR